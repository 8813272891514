import React from "react";
import { galleryData } from "../components/Gallery/gallery-data";
import Layout from "../components/Layout/Layout";
import { rideData } from "../components/RideCard/ride-data";

import Banner from "../images/home/main-slider/slide-4.jpg";
import { classNames } from "../utils/utils";

function importAll(r) {
  let images = [];
  r.keys().forEach((item, index) => {
    images.push(r(item).default);
  });
  return images;
}
const images = importAll(
  require.context("../images/gallery", false, /\.(png|jpe?g|svg)$/)
);

const imagesFirstHalf = images.splice(0, images.length / 2);
const imagesLastHalf = images.splice(images.length / 2 + 1, images.length);

export default function RideReport() {
  return (
    <Layout>
      <div className="absolute top-0 left-0 h-[500px] blur-[2px]">
        <img src={Banner} className="object-cover w-full h-full md:h-auto md:w-auto" />
      </div>
      <div className="container relative p-8 mx-auto text-center md:p-36">
        <h6 className="text-2xl font-light text-white uppercase before:content-['♦'] before:text-kr-yellow after:content-['♦'] after:text-kr-yellow before:pr-4 after:pl-4">
          MEDIA
        </h6>
        <h1 className="my-4 font-semibold text-white uppercase text-7xl">
          GALLERY
        </h1>
      </div>

      <section className="relative pb-12 bg-neutral-800 md:py-36">
        <div className="container px-4 py-12 mx-auto md:px-0">
          <h1 className="my-4 font-semibold text-center text-white uppercase text-7xl">
            A little more wait...
          </h1>
        </div>
      </section>
      {/* <section className="relative pb-12 bg-neutral-800 md:py-36">
        <div className="container px-4 py-12 mx-auto md:px-0">
          <div className="flex flex-wrap -m-1 md:-m-2">
            <div className="flex flex-wrap md:w-1/2">
              {imagesFirstHalf.map((data, index) => {
                return (
                  <div
                    className={classNames(
                      index % 3 === 0 ? "w-full" : "w-1/2",
                      "p-1 md:p-2"
                    )}
                  >
                    <img src={data} className="object-cover h-full" />
                  </div>
                );
              })}
            </div>
            <div className="flex flex-wrap md:w-1/2">
              {imagesLastHalf.map((data, index) => {
                return (
                  <div
                    className={classNames(
                      index % 7 === 0 ? "w-full" : "w-1/2",
                      "p-1 md:p-2"
                    )}
                  >
                    <img src={data} className="object-cover h-full" />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section> */}
    </Layout>
  );
}

export const Head = () => <title>Ride Report</title>;
